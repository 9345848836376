//订单导入模版
const ORDER_TEMP = 'https://cloudimg.linkgap.com/%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88%282022-01-08%29.xlsx'
//商品导入模版
const PRODUCT_TEMP = 'https://cloudimg.linkgap.com/%E5%95%86%E5%93%81%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88%282022-01-08%29.xlsx'
//报备经销商导入模版
const BB_DEALER_TEMP = 'https://cloudimg.linkgap.com/%E6%8A%A5%E5%A4%87%E7%BB%8F%E9%94%80%E5%95%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88%282022-01-08%29.xlsx'
//订单经销商导入模版
const DD_DEALER_TEMP = 'https://cloudimg.linkgap.com/%E8%AE%A2%E5%8D%95%E7%BB%8F%E9%94%80%E5%95%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88%282022-01-08%29.xlsx'
//小区导入模版
const COMMUNITY_TEMP = ''
//打款导入模版
const PAYMENT_TEMP = 'https://cloudimg.linkgap.com/%E6%89%93%E6%AC%BE%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88%282022-3-18%29.xlsx'


export {
  ORDER_TEMP,
  PRODUCT_TEMP,
  BB_DEALER_TEMP,
  DD_DEALER_TEMP,
  COMMUNITY_TEMP,
  PAYMENT_TEMP
}
