<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-01-18 18:55:53
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-12 16:09:37
-->
<template>
  <div class="password">
    <a-modal title="重置密码" style="top: 20px;" :width="400" centered v-model="visible" @ok="handleSubmit" :maskClosable="false">
      <a-form-model ref="form">
        <a-form-model-item label="新登录密码">
          <a-input v-model="loginPass" type="password" placeholder="新密码"/>
        </a-form-model-item>
        <a-form-model-item label="确认密码">
          <a-input type="password" v-model="password" placeholder="新密码"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      password: '',
      loginPass: '',
      id: '',
      userId: ''
    }
  },
  methods: {
    onsetRowData(row) {
      this.visible = true
      this.id = row.id
      this.userId = row.userId
    },
    handleSubmit() {
      if (this.password != this.loginPass) {
        return this.$notification.error({message: '两次密码不一致'})

      }
      this.axios.post('/api/base/system/dealer/manager/resetSystemPwd', {
        dealerId: this.id,
        password: this.loginPass
      }).then(res => {
        this.$notification.success({message: res.message})
        this.loginPass = ''
        this.password = ''
        this.visible = false
      })
    }
  }
}
</script>

<style lang="scss">

</style>
