<template>
  <a-card :bordered="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商名称">
              <a-input v-model="searchData.name" placeholder="请输入经销商名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
<!--          <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--            <a-form-model-item label="经销商编码">-->
<!--              <a-input v-model="searchData.dealerCode" placeholder="请输入经销商编码"></a-input>-->
<!--            </a-form-model-item>-->
<!--          </a-col>-->
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="大区事务所">
              <a-cascader
                :options="dealerList"
                v-model="regionalOffice"
                change-on-select
                placeholder="请选择大区/事务所"
                @change="onChange"
                :field-names="{ label: 'title', value: 'id', children: 'children' }"
              />
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商类型">
              <DictSelect
                field="dealerType"
                :value.sync="searchData.dealerType"
                style="width: 100%"
                placeholder="经销商类型"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="审核状态">
            <DictSelect field="verifyStatus" :value.sync="searchData.verifyStatus" style="width: 100%" placeholder="审核状态"></DictSelect>
          </a-form-model-item>
        </a-col> -->
          <!-- <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="合作状态">
              <DictSelect field="cooperateStatus" :value.sync="searchData.cooperateStatus" style="width: 100%" placeholder="生成方式"></DictSelect>
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="联系人">
            <a-input v-model="searchData.contacts" placeholder="请输入联系人"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="联系电话">
            <a-input v-model="searchData.phone" placeholder="请输入联系人电话"></a-input>
          </a-form-model-item>
        </a-col> -->
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="帐号">
              <a-input v-model="searchData.account" placeholder="请输入帐号" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button type="primary" @click="toHandler('add')" icon="plus" v-if="isCusInfoAdd">新增</a-button>
        <a-button
          type="primary"
          v-if="selectedRowKeys.length >= 1 && isCusInfoEdit"
          @click="toHandler('edit')"
          icon="edit"
          >编辑</a-button
        >
        <a-button
          type="primary"
          v-if="selectedRowKeys.length >= 1 && isCusInfoDel"
          @click="toHandler('del')"
          icon="delete"
          >删除</a-button
        >
        <a-button
          type="primary"
          v-if="selectedRowKeys.length >= 1 && isCusInfoSelect"
          @click="toHandler('check')"
          icon="eye"
          >查看</a-button
        >
        <a-button
          v-if="isTypeCharge && selectedRowKeys.length > 0  && item[0].verifyStatus == 2 && item[0].dealerClassify == 2"
          type="primary"
          @click="typeChargeApply"
          icon="sync"
        >类型转换</a-button>
        <!-- <a-button
          type="primary"
          v-if="isSubmit && selectedRowKeys.length > 0 && item[0].verifyStatus == 0"
          @click="onSubmitAudit(0)"
          icon="form"
          >提交审核
        </a-button> -->
        <!-- <a-button
          v-if="isBack && selectedRowKeys.length > 0 && item[0].verifyStatus == 1"
          type="primary"
          @click="toHandler('rollbackStatus')"
          icon="rollback"
          >撤审</a-button
        > -->
        <!-- <a-button
          type="primary"
          v-if="selectedRowKeys.length > 0 && item[0].verifyStatus == 1 && isVerify"
          @click="onAudit"
          icon="form"
          >审核
        </a-button> -->
        <!-- <a-button
          v-if="isAudi && selectedRowKeys.length > 1 && item[0].verifyStatus == 1"
          type="primary"
          @click="toHandlerAll"
          >批量审核</a-button
        > -->
        <a-button type="default" v-if="selectedRowKeys.length >= 1 && isCusInfoPwd" @click="onPassword()"
          >重置密码</a-button
        >
        <a-button type="default" v-if="selectedRowKeys.length >= 1 && isUpdateContact" @click="toHandler('contact')"
          >修改联系人</a-button
        >
        <!-- <a-button type="default" v-if="selectedRowKeys.length >= 1 && isUpdateAddress" @click="toHandler('address')">修改收货地址</a-button>
      <a-button type="default" v-if="selectedRowKeys.length >= 1 && isInvoice" @click="toHandler('invoice')">修改发票</a-button> -->
        <a-upload
          name="file"
          :action="uploadUrl"
          :headers="headers"
          :showUploadList="false"
          @change="uploadChange"
        >
          <a-button v-if="isReceiptImport" icon="upload" type="default">导入</a-button>
        </a-upload>
        <downLoad
          method="get"
          api="/api/dealer/dealer/dealerInfo/excelDealerInfo"
          :params="searchData"
          name="客户资料.xls"
          >导出经销商资料</downLoad
        >
        <a-button type="default" icon="download"  @click="downLoadTemp">
          <slot>下载导入模版</slot>
        </a-button>
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="{ type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :rowClassName="rowClassName"
         :customRow="changeTableRow"
      >
        <span slot="dealerType" slot-scope="text">
          <a-tag v-if="text == 1">中心店</a-tag>
          <a-tag v-if="text == 2">核心店</a-tag>
          <a-tag v-if="text == 3">一般店</a-tag>
          <a-tag v-if="text == 4">核心店（预备）</a-tag>
        </span>
        <span slot="verifyStatus" slot-scope="text">
          <a-tag v-if="text == 0" color="#87d068">待处理</a-tag>
          <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
          <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
          <a-tag v-if="text == 3" color="#67c23a">审核拒绝</a-tag>
        </span>
        <span slot="numberOfEmployees" slot-scope="text,row">
          {{row.haveStaffNum}} / {{row.numberOfEmployees}}
        </span>
        <!-- <span slot="cooperateStatus" slot-scope="text">
        <a-tag v-if="text == 1" color="#87d068">合作中</a-tag>
        <a-tag v-if="text == 2">合作终止</a-tag>
      </span> -->
      </a-table>
      <!-- 编辑表单 -->
      <CustomerInfo-edit-modal ref="CustomerInfoEditModal" @reload="getData"></CustomerInfo-edit-modal>
      <CustomerInfoCheckModal ref="CustomerInfoCheckModal" @reload="getData"></CustomerInfoCheckModal>
      <CustomerContactsModal ref="CustomerContactsModal" @reload="getData"></CustomerContactsModal>
      <!--查看-->
      <Password ref="password"></Password>
      <a-modal v-model="visibleCharge" title="类型转换申请" @ok="handleOkCharge"  @cancel="handleCanceCharge" :maskClosable="false" width="40%" :confirm-loading="confirmLoading">
        <a-form-model ref="formCharge" :model="rowDataCharge" :rules="formRule" :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
        <a-form-model-item label="权限组" prop="applyTemplateId">
          <a-select placeholder="请选择权限组" v-model="rowDataCharge.applyTemplateId">
            <a-select-option :value="item.id" v-for="item in templateList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
<!--         <a-form-model-item label="经销商编码" prop="dealerCode">-->
<!--            <a-input v-model="rowDataCharge.dealerCode"></a-input>-->
<!--          </a-form-model-item>-->
        <a-form-model-item label="备注">
          <a-textarea v-model="rowDataCharge.remarkCharge"></a-textarea>
        </a-form-model-item>
      </a-form-model>
      </a-modal>

      <a-modal
        v-model="visible"
        title="审核"
        @ok="handleOk"
        @cancel="handleCance"
        :maskClosable="false"
        width="40%"
        :confirm-loading="confirmLoading"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
          <a-form-model-item label="是否通过">
            <a-radio-group v-model="status">
              <a-radio :value="2"> 通过 </a-radio>
              <a-radio :value="3"> 拒绝 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-textarea v-model="verifyRemark"></a-textarea>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-modal
        v-model="visibleAll"
        title="批量审核"
        @ok="handleOkAll"
        @cancel="handleCanceAll"
        :maskClosable="false"
        width="40%"
        :confirm-loading="confirmLoading"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
          <a-form-model-item label="是否通过">
            <a-radio-group v-model="statusAll">
              <a-radio :value="2"> 通过 </a-radio>
              <a-radio :value="3"> 拒绝 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-textarea v-model="verifyRemark"></a-textarea>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import Password from './components/password'
import { columns } from './components/colums.js'
import CustomerInfoEditModal from './components/CustomerInfoEditModal.vue'
import CustomerContactsModal from './components/CustomerContactsModal.vue'
import CustomerInfoCheckModal from './components/CustomerInfoCheckModal.vue'
import { delCustomerInfo, listCustomerInfo, rollbackStatus, addTypeApplyInfo } from './api/CustomerInfoApi'
import { checkPermission } from '@/utils/permissions'
import {baseURL} from '@/utils/request';
import {BB_DEALER_TEMP} from '@/utils/downloadTempHref';

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'customerInfo',
  components: {
    CustomerInfoEditModal,
    Password,
    CustomerInfoCheckModal,
    CustomerContactsModal
  },
  data() {
    return {
      columns: [
        {
          title: '经销商名称',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          ellipsis: true,
          width: 200,
          scopedSlots: { customRender: 'name' },
        },
        // {
        //   title: '经销商编码',
        //   dataIndex: 'dealerCode',
        //   key: 'dealerCode',
        //   align: 'center',
        //   ellipsis: true,
        //   width: 150,
        // },
        {
          title: '担当',
          dataIndex: 'managerName',
          key: 'managerName',
          align: 'center',
          ellipsis: true,
          width: 150,
        },
        {
          title: '大区',
          dataIndex: 'area',
          key: 'area',
          align:'center',
          ellipsis:true,
          width: 200,
        },
        {
          title: '事务所',
          dataIndex: 'firmName',
          key: 'firmName',
          width:150,
          align:'center',
          ellipsis:true,
        },
        {
          title: '经销商类型',
          dataIndex: 'dealerType',
          key: 'dealerType',
          width: 150,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'dealerType' },
        },
        // {
        //   title: '审核状态',
        //   dataIndex: 'verifyStatus',
        //   key: 'verifyStatus',
        //   width:150,
        //   align:'center',
        //   ellipsis:true,
        //   scopedSlots: { customRender: 'verifyStatus'}
        // },
        {
          title: '账号',
          dataIndex: 'account',
          key: 'account',
          width: 200,
          align: 'center',
          ellipsis: true,
        },
        // {
        //   title: '员工数量（已有/可建）',
        //   dataIndex: 'numberOfEmployees',
        //   key: 'numberOfEmployees',
        //   width:200,
        //   align:'center',
        //   scopedSlots: { customRender: 'numberOfEmployees'}
        // },
      ],
      statusAll:'',
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      statusList: [],
      item: [],
      visible: false,
      visibleAll: false,
      verifyRemark: '',
      dataList: [],
      confirmLoading: false,
      isCusInfoAdd: checkPermission('pre:cus:info:add'),
      isCusInfoEdit: checkPermission('pre:cus:info:edit'),
      isCusInfoDel: checkPermission('pre:cus:info:del'),
      isCusInfoSelect: checkPermission('pre:cus:info:select'),
      isCusInfoPwd: checkPermission('pre:cus:info:select'),
      isCusInfoFreeze: checkPermission('pre:cus:info:freeze'),
      isCusInfoThaw: checkPermission('pre:cus:info:thaw'),
      isUpdateContact: checkPermission('pre:cus:info:updateContact'),
      isUpdateAddress: checkPermission('pre:cus:info:updateAddress'),
      isInvoice: checkPermission('pre:cus:info:updateInvoice'),
      isSubmit: checkPermission('pre:cus:info:submit'),
      isVerify: checkPermission('pre:cus:info:verify'),
      isAudi: checkPermission('pre:cus:info:audi'),
      isBack: checkPermission('pre:cus:info:back'),
      isReceiptImport: checkPermission('pre:cus:receipt:importR'),
      isTypeCharge: checkPermission('pre:cus:info:typeCharge:report'),
      status: [
        {
          key: ' ',
          label: '全部',
        },
        {
          key: true,
          label: '正常',
        },
        {
          key: false,
          label: '冻结',
        },
      ],
      dealerList: [],
      regionalOffice: [],
      //上传文件地址
      uploadUrl: baseURL + '/api/dealer/dealer/dealerInfo/excelReaderNewTemp',
      //上传文件请求头
      headers: {
        'Authorization-Admin': this.$store.getters.token,
      },
      downloadTempHref: BB_DEALER_TEMP,
      visibleCharge: false,
      applyTemplate:'',
      remarkCharge:'',
      dealerCode:'',
      templateList:[],
      rowDataCharge:{},
      formRule: {
        applyTemplateId:[{ required: true, message: '请选择权限组', trigger: 'change' }],
        // dealerCode: [
        //   {required: true, message: '请输入经销商编码', trigger: 'blur'}
        // ],
      },
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    // custoStatus() {
    //   if(this.statusList.length > 0) {
    //     return this.statusList.array.every(value => {
    //       return value != this.statusList[0]
    //     })
    //   }else {
    //     return true
    //   }
    // }
  },
  methods: {
    downLoadTemp (){
      window.open(this.downloadTempHref, '_blank')
    },
    //导入
    uploadChange (info) {
      this.tableLoading = true
      setTimeout(()=>{
        if(info.file.response){
          let hint = info.file.response
          if(hint.code === 200) {
            this.tableLoading = false
            this.$message.success(hint.message)
            this.getData()
          }else if(hint.code === 500) {
            this.tableLoading = false
            this.$message.error(hint.message)
          }
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList)
          }
        }
      })

    },
    // 审核弹框
    toHandlerInfo() {
      let _this = this
      if (_this.item.length === 0) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      _this.visible = true
    },
    handleOk() {
      this.confirmLoading = true
      let params = {
        verifyStatus: this.status,
        verifyRemark: this.verifyRemark,
        id: this.item[0].id,
      }
      this.axios
        .post('/api/dealer/dealer/dealerInfo/verifyDealerInfo', params)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.message)
            this.visible = false
            this.getData()
          }
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    handleCance() {
      this.confirmLoading = false
      this.visible = false
      this.status = ''
      this.verifyRemark = ''
    },
    // 批量审核弹框
    toHandlerAll() {
      let everyResult = this.selectedRows.every((item) => {
        return item.verifyStatus === 1
      })
      if (everyResult) {
        this.visibleAll = true
        this.dataList = this.selectedRows
      } else {
        this.$notification.error({ message: '请选择相同待审核记录' })
      }
    },
    handleOkAll() {
      this.confirmLoading = true
      let params = {
        authStatus: this.statusAll,
        verifyRemark: this.verifyRemark,
      }
      params.ids = this.dataList.map((item) => item.id)
      this.axios
        .post('/api/dealer/dealer/dealerInfo/verifyDealerList', params)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.message)
            this.visibleAll = false
            this.getData()
          }
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    handleCanceAll() {
      this.confirmLoading = false
      this.visibleAll = false
      this.statusAll = ''
      this.verifyRemark = ''
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      //此页面只查询报备经销商经销商
      this.searchData.dealerClassify = 2
      listCustomerInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))

      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        // 获取大区事务所经销商数据
        this.dealerList = res.body
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.item = selectedRows
    },
    // 提交审核
    onSubmitAudit(value) {
      let list = []
      if (this.item.length === 0) {
        this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      if (value == 0) {
        this.item.filter((e) => {
          list.push({
            id: e.id,
            verifyStatus: 1,
            verifyRemark: this.verifyRemark,
          })
        })
      }
      this.axios.post('/api/dealer/dealer/dealerInfo/updateStatus/' + list[0].id).then((res) => {
        if (res.code == 200) {
          this.rejectReason = ''
          this.$notification.success({ message: res.message })
          this.visible = false
          this.getData()
        } else {
          this.$notification.error({ message: res.message })
          this.getData()
        }
      })
    },

    // 类型转换申请
    typeChargeApply() {
      let _this=this;
      if (_this.item.length === 0) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      // 排除现有经销商的角色模板数据
      this.axios.get('/api/base/dealer/dealerTemplate/listAllByType/2').then((res) => {
        this.templateList = res.body
      })
      _this.visibleCharge = true;
    },

    handleOkCharge(){
      this.$refs.formCharge.validate(async (valid) => {
        if (!valid) {
          return
        }
      this.confirmLoading = true
      let dealerIds = []
      this.item.filter((e) => {
        dealerIds.push(e.id)
      })
       let templateType = this.templateList.filter(item=>{
         return item.id === this.rowDataCharge.applyTemplateId
       })
      const params = {
       applyClassify: templateType[0].templateType,
        applyTemplate: templateType[0].id,
        remark: this.rowDataCharge.remarkCharge,
        dealerIds: dealerIds,
        // dealerCode:this.rowDataCharge.dealerCode
      }
      addTypeApplyInfo(params).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visibleCharge = false
          this.getData()
        }
      }).finally(() => {
        this.confirmLoading = false
        this.visibleCharge = false
        this.applyTemplate = ''
        this.remarkCharge = ''
        // this.dealerCode = ''
        this.rowDataCharge = {}
      })
      })
    },

    handleCanceCharge(){
      this.confirmLoading = false
      this.visibleCharge = false
      this.applyTemplate = ''
      this.remarkCharge = ''
      // this.dealerCode = ''
      this.rowDataCharge = {}
    },

    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1
      this.getData()
    },

    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    handleChange(value) {},
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
            this.item = this.selectedRows
          },
        },
      }
    },
    onPassword() {
      const _this = this
      if (_this.item.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      _this.$refs.password.onsetRowData(_this.item[0])
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.CustomerInfoEditModal.setRowData({}, '2', 'add')
        return
      }
      if (_this.item.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.CustomerInfoEditModal.setRowData(_this.item[0], '2', 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delCustomerInfo(_this.item[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'rollbackStatus':
          _this.$confirm({
            title: '撤回警告',
            content: '确认要撤回此条记录的提交审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await rollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.$bus.$emit('getList')
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.CustomerInfoCheckModal.setRowData(_this.item[0], 'check', '2')
          break
        case 'contact':
          _this.$refs.CustomerContactsModal.setRowData(_this.item[0], '2', 'edit', '2')
          break
        case 'address':
          _this.$refs.CustomerInfoEditModal.setRowData(_this.item[0], '2', 'edit', '3')
          break
        case 'invoice':
          _this.$refs.CustomerInfoEditModal.setRowData(_this.item[0], '2', 'edit', '4')
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
